/* tailwindcss */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* font -> Roboto */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* all */
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

/* html */
html {
  height: 100%;
  overflow: hidden;
}

/* body */
body {
  overflow: auto;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

/* scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* ancher tag */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

/* media queries */

/* >= 768px */
@media screen and (min-width: 768px) {
  html {
    font-size: 110%;
  }
}
